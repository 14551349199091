import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getNowPlaying } from '../utils/spotify'

function Footer() {
  const currentYear = new Date().getFullYear()

  const [song, setSong] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getNowPlaying();
      if (data.is_playing) {
        let song_name = data.item.name
        song_name = String(song_name).toLowerCase();
        setIsPlaying(true);
        setSong(song_name);
      } else {
        setIsPlaying(false);
        setSong("playback paused");
      }
    }

    fetchData();
  },);

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        const data = await getNowPlaying();
        if (data.is_playing) {
          let song_name = data.item.name
          song_name = String(song_name).toLowerCase();
          setSong(song_name);
        } else {
          setSong("playback paused");
        }
      }

      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  },);

  return (
    <div>
      <footer className="mt-2 container flex flex-wrap items-center justify-between sm:mx-auto max-w-7xl sm:mb-5 bg-base-200 px-2 sm:px-4 sm:py-2.5 rounded-full">
        <span className="text-md text-gray-400 ml-3">&copy; {currentYear} <Link to="/"
          className="font-bold hover:text-white transition duration-500 ease-in-out">notcoderguy</Link>.
        </span>

        <span className="flex flex-wrap items-center my-3 mr-6 text-md text-white">
          <div className={`md:flex sm:flex-row space-x-2 hidden text-sm ${isPlaying ? 'animate-pulse' : ''}`}>
            <svg className="h-4 w-4 mt-[2px]" viewBox="0 0 168 168">
              <path fill="#22c55e"
                d="M83.996.277C37.747.277.253 37.77.253 84.019c0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l.001-.004zm38.404 120.78a5.217 5.217 0 01-7.18 1.73c-19.662-12.01-44.414-14.73-73.564-8.07a5.222 5.222 0 01-6.249-3.93 5.213 5.213 0 013.926-6.25c31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-.903-8.148-4.35a6.538 6.538 0 014.354-8.143c30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-.001zm.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219a7.835 7.835 0 015.221-9.771c29.581-8.98 78.756-7.245 109.83 11.202a7.823 7.823 0 012.74 10.733c-2.2 3.722-7.02 4.949-10.73 2.739z">
              </path>
            </svg>
            <div className="inline-flex truncate">
              <p className="capsize font-bold"><a href="https://spotifyx.notcoderguy.com/" target="_blank" rel="noopener noreferrer">{song}.</a></p>
            </div>
          </div>

          <div className={`md:hidden flex flex-row ${isPlaying ? 'animate-pulse' : ''}`}>
            <a href="https://spotifyx.notcoderguy.com/" target="_blank" rel="noopener noreferrer">
              <svg className="h-5 w-5" viewBox="0 0 168 168">
                <path fill="#22c55e"
                  d="M83.996.277C37.747.277.253 37.77.253 84.019c0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l.001-.004zm38.404 120.78a5.217 5.217 0 01-7.18 1.73c-19.662-12.01-44.414-14.73-73.564-8.07a5.222 5.222 0 01-6.249-3.93 5.213 5.213 0 013.926-6.25c31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-.903-8.148-4.35a6.538 6.538 0 014.354-8.143c30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-.001zm.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219a7.835 7.835 0 015.221-9.771c29.581-8.98 78.756-7.245 109.83 11.202a7.823 7.823 0 012.74 10.733c-2.2 3.722-7.02 4.949-10.73 2.739z">
                </path>
              </svg>
            </a>
          </div>
        </span>
      </footer>
    </div>
  )
}

export default Footer